import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './BodhaSoft_logo_purple-removebg.png';
import { FaCode } from 'react-icons/fa';
import { IoPlayOutline } from 'react-icons/io5';
import './mcqquestion.css';
import { MdHome } from 'react-icons/md';
import { MDBBtn, MDBCollapse, MDBIcon } from 'mdb-react-ui-kit';
import { PiTimerBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';


const Mcqquestions = () => {
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]); // Empty initially, fetched from API
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submitted, setSubmitted] = useState([]);
  const [markedForReview, setMarkedForReview] = useState([]);
  const [viewedQuestions, setViewedQuestions] = useState([]);
  const [showTerminal, setShowTerminal] = useState(false);

  // Timer logic
  const initialTimeInSeconds = 40 * 60; // 40 minutes
  const [timer, setTimer] = useState(initialTimeInSeconds);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(interval);
        handleFinalSubmit(); // Auto-submit when timer reaches zero
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  // Fetch data from API
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('https://examinationbackend.bodhasoftworkshop.in/api/mcq-questions');
        setQuestions(response.data);
        console.log(response.data);
        setSelectedOptions(Array(response.data.length).fill(null)); 
        setSubmitted(Array(response.data.length).fill(false)); 
        setMarkedForReview(Array(response.data.length).fill(false));
        setViewedQuestions(Array(response.data.length).fill(false));
      } catch (error) {
        console.error('Failed to fetch questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleQuestionClick = (index) => {
    setSelectedQuestion(index);
    setShowTerminal(false);
    const newViewedQuestions = [...viewedQuestions];
    newViewedQuestions[index] = true;
    setViewedQuestions(newViewedQuestions);
  };

  const handleOptionChange = (index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[selectedQuestion] = index;
    setSelectedOptions(newSelectedOptions);
  };

  const handleSubmit = () => {
    const newSubmitted = [...submitted];
    newSubmitted[selectedQuestion] = true;
    setSubmitted(newSubmitted);
    handleNextQuestion();
  };

  const handleMarkForReview = () => {
    const newMarkedForReview = [...markedForReview];
    newMarkedForReview[selectedQuestion] = !newMarkedForReview[selectedQuestion];
    setMarkedForReview(newMarkedForReview);
  };

  const handleNextQuestion = () => {
    const newViewedQuestions = [...viewedQuestions];
    if (selectedOptions[selectedQuestion] === null && !markedForReview[selectedQuestion]) {
      newViewedQuestions[selectedQuestion] = true;
    }
    setViewedQuestions(newViewedQuestions);
    setSelectedQuestion((prev) => (prev < questions.length - 1 ? prev + 1 : prev));
    setShowTerminal(false);
  };

  const handlePreviousQuestion = () => {
    setSelectedQuestion((prev) => (prev > 0 ? prev - 1 : prev));
    setShowTerminal(false);
  };

  const handleFinalSubmit = async () => {
    const email = localStorage.getItem('email');
    const rollno = localStorage.getItem('rollno');
  
    let score = 0;
    const formattedAnswers = selectedOptions.map((optionIndex, index) => {
      const question = questions[index];
      const correctAnswer = question.correctOption; 
      const selectedAnswer = optionIndex !== null ? question.options[optionIndex] : null;
      
      if (selectedAnswer === correctAnswer) {
        score += 1;
      }
      
      return {
        questionText: question.question,
        options: question.options,
        selectedOption: selectedAnswer,
        questionIndex: index,
      };
    });
  

    const payload = {
      email: email,  
      rollno: rollno,  
      answers: formattedAnswers,  
      score: score, 
    };
  
    try {
      const response = await axios.post('https://examinationbackend.bodhasoftworkshop.in/api/submit-answers', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      alert("Your Answers Have been Successfully Submitted");
      console.log(payload);
      navigate('/dashboard');
    } catch (error) {
      alert("Please Try Again");
      console.error('Error submitting answers:', error);
    }
  };
  
  

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div className='Navbar'>
        <img src={logo} className='logo' alt="logo" />
        <div>
          Time Left: {formatTime(timer)}
        </div>
      </div>

      <div className="test-page">
        <MDBCollapse open={isOpen}>
          <div className="question-tabs">
            <div className='qnumbers'>
              {questions.map((_, index) => (
                <div
                  key={index}
                  className={`question-number 
                        ${submitted[index] ? 'submitted' : markedForReview[index] ? 'review' : viewedQuestions[index] && selectedOptions[index] === null ? 'not-answered' : ''}
                        ${selectedQuestion === index ? 'active' : ''}`}
                  onClick={() => handleQuestionClick(index)}
                >
                  {`Q${index + 1}`}
                </div>
              ))}
            </div>
          </div>
        </MDBCollapse>
        <div className="content">
          {questions.length > 0 && (
            <>
              <div className="question-details">
                <MDBBtn onClick={toggleOpen} style={{ backgroundColor: "blueviolet", padding: "5px 10px", fontSize: "20px" }}>
                  <MDBIcon fas icon="bars" /></MDBBtn><br />
                <strong>Question {selectedQuestion + 1}</strong>
                <p className='question'>{questions[selectedQuestion].question}</p>
              </div>
              <div className="code-editor">
                <div className="options">
                  {questions[selectedQuestion].options.map((option, index) => (
                    <label key={index} className={`option ${selectedOptions[selectedQuestion] === index ? 'selected' : ''}`}>
                      <input
                        type="radio"
                        name={`question${selectedQuestion}`}
                        value={index}
                        checked={selectedOptions[selectedQuestion] === index}
                        onChange={() => handleOptionChange(index)}
                        style={{
                          width: '19px',
                          height: '19px',
                        }}
                      />
                      <text className='optiontext'>{option}</text>
                    </label>
                  ))}
                </div>
                <div className="buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button onClick={handleSubmit} className='runbtn'>Save&next</button>
                </div>
                <div className="mark-review">
                  <label>
                    <input
                      type="checkbox"
                      checked={markedForReview[selectedQuestion]}
                      onChange={handleMarkForReview}
                    />
                    Mark as review
                  </label>
                </div>
                <div className='btngroup'>
                  <button onClick={handlePreviousQuestion} disabled={selectedQuestion === 0} className='navigationbtns'>
                    Previous
                  </button>
                  {selectedQuestion === questions.length - 1 ? (
                    <button onClick={handleFinalSubmit} className='navigationbtns'>
                      Submit
                    </button>
                  ) : (
                    <button onClick={handleNextQuestion} className='navigationbtns'>
                      Next
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Mcqquestions;
