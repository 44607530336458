import React, { useState } from 'react'; // Importing React and useState hook for managing state
import './Login.css'; // Importing CSS for styling the component
import logo from './Programmer-amico.png'; // Importing logo image for the login page
import axios from 'axios'; // Importing axios for making HTTP requests
import Navbar from './Navbar'; // Importing Navbar component for navigation
import { useNavigate } from 'react-router-dom';


const Login = () => {
  // State variables for managing form inputs and messages
  const [email, setEmail] = useState(''); // State for email input
  const [rollno, setRollno] = useState(''); // State for roll number input
  const [password, setPassword] = useState(''); // State for password input
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Custom validation
    if (!email || !rollno || !password) {
      alert("Please fill in all fields.");
      return;
    }
  
    // Email validation regex (basic)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
  
  
    try {
      const response = await axios.post('https://examinationbackend.bodhasoftworkshop.in/api/login', {
        email,
        rollno,
        password,
      });
  
      if (response.status === 200) {
        alert("Login successful");
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('email', email);  // Store email
        localStorage.setItem('rollno', rollno);
        navigate('/dashboard');
      } else {
        alert('Invalid credentials, please try again.');
      }
    } catch (error) {
      alert('Invalid credentials, please try again.');
    }
  };
  

  return (
    <div className='l-mainpage'>
      {/* Navbar component for navigation */}
      <Navbar />
      <div className='l-container'>
        <div className="l-box1">
          {/* Logo image displayed on the login page */}
          <img src={logo} className='loginImg' alt="Login Illustration" />
        </div>
        <div className="l-box2">
          <div className="login-box">
            <h1 className="login-heading">Login</h1>
            <form onSubmit={handleSubmit}>
              {/* Email input field */}
              <div className="form-group">
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder='Enter your email'
                  className="input-field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Update email state on change
                  required
                />
              </div>
              {/* Roll number input field */}
              <div className="form-group">
                <label htmlFor="rollno">Roll No</label>
                <input
                  type="text"
                  placeholder='Enter your Roll number'
                  id="rollno"
                  name="rollno"
                  className="input-field"
                  value={rollno}
                  onChange={(e) => setRollno(e.target.value)} // Update roll number state on change
                  required
                />
              </div>
              {/* Password input field with visibility toggle */}
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"} // Toggle between text and password type
                    id="password"
                    placeholder='Enter your password'
                    name="password"
                    className="input-field"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // Update password state on change
                    required
                  />
                  {/* Button to toggle password visibility */}
                  <button type="button" onClick={togglePasswordVisibility} className="show-password-button">
                    <i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                  </button>
                </div>
                {/* Link for forgotten password */}
                <a className="forgot-password">Forgot password?</a>
              </div>
              {/* Submit button for the form */}
              <center>
                <button type="submit" className="sign-in-button">Sign In</button>
              </center>
            </form>
            {/* Prompt for registration if user doesn't have an account */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login; // Exporting the Login component for use in other parts of the application
